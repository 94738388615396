import { ExternalQuestion, RespondentAnswerTypes } from '@sm/question-widgets/respondent-survey';
import {
  CheckBoxAnswerType,
  DropdownAnswerType,
  MatrixAnswerType,
  OpenEndedAnswerType,
  MultipleChoiceAnswerType,
  ImageChoiceAnswerType,
  ExternalMultipleChoiceRespondentSurvey,
  NpsAnswerType,
  StarRatingAnswerType,
  ResponseValue,
  ExternalCheckBoxRespondentSurvey,
  ExternalImageChoiceRespondentSurvey,
  AnswerOther,
  ExternalDropdownRespondentSurvey,
  ExternalStarRatingRespondentSurvey,
  ExternalMatrixRespondentSurvey,
} from '@sm/question-definitions';
import { QuestionAnswers } from '~app/pages/SurveyTaking/v2/types';
import { ExistingAnswers, SurveyQuestionFamily, SurveyQuestionVariant } from '~lib/generatedGqlTypes';

type VisibleAnswerOptions = {
  answers: string[];
  fieldSets: string[];
  answersWeighted: string[];
  answersImage: string[];
  other: string | null;
  answerNA: string | null;
  fieldSet: string | null;
};

const findMappedResponse = (responses: ExistingAnswers[]): ResponseValue | null => {
  const response = responses.find(r => r.answerId);
  if (response) {
    return {
      id: response.answerId ?? '',
      value: response.answerId ?? '',
    } as ResponseValue;
  }

  return null;
};

const findMappedOtherResponse = (responses: ExistingAnswers[], answerOther?: AnswerOther): ResponseValue | null => {
  if (answerOther) {
    const otherResponse = responses.find(r => r.answerText);
    /**
     * allow empty Other text/answerText for other as answer type - needed for answers populated via smParams
     * the conditions are specific to question types supported for email embedded first question
     */
    const mapEmptyOtherResponse = answerOther.type === 'ANSWER' && responses.find(r => r.answerId === answerOther.id);
    if (otherResponse || mapEmptyOtherResponse) {
      return {
        id: answerOther.id,
        value: otherResponse?.answerText ?? '',
        type: answerOther.type,
      };
    }
  }

  return null;
};

const mapOpenEndedAnswerValues = (responses: ExistingAnswers[]): OpenEndedAnswerType['values'] => {
  const firstResponse = responses && responses.length > 0 ? responses[0] : null;

  if (!firstResponse?.answerText) {
    return [];
  }

  return [{ id: firstResponse.questionId, value: firstResponse.answerText }];
};

const mapWrittenCommentPerRowForMatrix = (
  responses: MatrixAnswerType['values'],
  commentsPerRow: ExistingAnswers[],
  answerOther: AnswerOther
): MatrixAnswerType['values'] => {
  commentsPerRow.forEach(comment => {
    const matchedResponse = responses.find(response => response.id === comment.answerId);
    if (matchedResponse) {
      matchedResponse.value.push({ id: answerOther.id, value: comment.answerText ?? '', type: answerOther.type });
    } else {
      responses.push({
        id: comment.answerId as string,
        value: [{ id: answerOther.id, value: comment.answerText ?? '', type: answerOther.type }],
      });
    }
  });
  return responses;
};

const mapMatrixRadioAnswerValues = (
  responses: ExistingAnswers[],
  question: Pick<ExternalMatrixRespondentSurvey, 'id' | 'answerOther'>
): MatrixAnswerType['values'] => {
  const mappedResponses = responses
    .filter(response => !!response.answerId && !!response.answerNum)
    .map(response => ({
      id: response.answerId as string,
      value: [{ id: response.answerNum as string, value: response.answerNum as string }],
    }));

  const commentsPerRow = responses.filter(response => !!response.answerText && !!response.answerId);
  if (commentsPerRow.length > 0 && question.answerOther?.type === 'COMMENT_COLUMN') {
    return mapWrittenCommentPerRowForMatrix(mappedResponses, commentsPerRow, question.answerOther as AnswerOther);
  }

  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);
  if (mappedOtherResponse?.type === 'COMMENT') {
    return [...mappedResponses, { ...mappedOtherResponse, value: [mappedOtherResponse] }];
  }

  return mappedResponses;
};

const mapManyMatrixCheckboxAnswerValues = (
  responses: ExistingAnswers[],
  question: Pick<ExternalMatrixRespondentSurvey, 'id' | 'answerOther'>
): MatrixAnswerType['values'] => {
  const responseMap = new Map<string, ResponseValue<string>[]>();

  responses.forEach(({ answerId, answerNum }) => {
    if (answerId && answerNum) {
      if (!responseMap.has(answerId)) {
        responseMap.set(answerId, []);
      }

      const answerArray = responseMap.get(answerId);
      answerArray?.push({ id: answerNum, value: answerNum });
    }
  });

  const mappedResponses = Array.from(responseMap.entries()).map(([id, value]) => ({ id, value }));
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);
  if (mappedOtherResponse?.type === 'COMMENT') {
    return [...mappedResponses, { ...mappedOtherResponse, value: [mappedOtherResponse] }];
  }

  return mappedResponses;
};

const mapMatrixOfDropdownMenuAnswerValues = (
  responses: ExistingAnswers[],
  question: Pick<ExternalMatrixRespondentSurvey, 'id' | 'answerOther'>
): MatrixAnswerType['values'] => {
  const filteredResponses = responses.filter(
    response => !!response.answerId && !!response.answerNum && !!response.answerType
  );

  const responseMap = new Map<string, ResponseValue<string>[]>();

  filteredResponses.forEach(({ answerId, answerNum, answerType }) => {
    if (!responseMap.has(answerId as string)) {
      responseMap.set(answerId as string, []);
    }
    responseMap.get(answerId as string)?.push({ id: answerNum as string, value: answerType as string });
  });

  const mappedResponses = Array.from(responseMap.entries()).map(([id, value]) => ({ id, value }));
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);
  if (mappedOtherResponse?.type === 'COMMENT') {
    return [...mappedResponses, { ...mappedOtherResponse, value: [mappedOtherResponse] }];
  }

  return mappedResponses;
};

const mapMultipleChoiceAnswerValues = (
  responses: ExistingAnswers[],
  question: Pick<ExternalMultipleChoiceRespondentSurvey, 'id' | 'answerOther'>
): MultipleChoiceAnswerType['values'] => {
  const mappedResponse = findMappedResponse(responses);
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);

  if (mappedOtherResponse?.type === 'ANSWER') {
    return [mappedOtherResponse];
  }

  if (mappedOtherResponse?.type === 'COMMENT') {
    return mappedResponse ? [mappedResponse, mappedOtherResponse] : [mappedOtherResponse];
  }

  return mappedResponse ? [mappedResponse] : [];
};

function mapSingleAnswerDropdown(
  responses: ExistingAnswers[],
  question: Pick<ExternalDropdownRespondentSurvey, 'id' | 'answerOther'>
): DropdownAnswerType['values'] {
  const mappedResponse = findMappedResponse(responses);
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);

  if (mappedOtherResponse?.type === 'ANSWER') {
    return [mappedOtherResponse];
  }

  if (mappedOtherResponse?.type === 'COMMENT') {
    return mappedResponse ? [mappedResponse, mappedOtherResponse] : [mappedOtherResponse];
  }

  return mappedResponse ? [mappedResponse] : [];
}

const mapCheckBoxAnswerValues = (
  responses: ExistingAnswers[],
  question: ExternalCheckBoxRespondentSurvey
): CheckBoxAnswerType['values'] => {
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);
  const mappedResponses: CheckBoxAnswerType['values'] = responses
    .filter(r => !!r.answerId)
    .map(r => {
      // mapps NOTA answer to NOTA type for checkbox
      if (question.answerNA && question.answerNA.id === r.answerId) {
        return { id: r.answerId as string, value: r.answerId as string, type: 'NOTA' };
      }

      // regular checkbox mapping
      return {
        id: r.answerId as string,
        value: r.answerId as string,
      };
    });

  if (mappedOtherResponse) {
    return [...mappedResponses.filter(({ id }) => id !== mappedOtherResponse.id), mappedOtherResponse];
  }

  return mappedResponses;
};

const mapSingleAnswerImageValue = (responses: ExistingAnswers[]): ImageChoiceAnswerType['values'] => {
  const [response] = responses;
  if (response) {
    return [
      {
        id: response.answerId ?? '',
        value: response.answerId ?? '',
      },
    ];
  }

  return [];
};

const mapMultipleAnswerImageValue = (
  responses: ExistingAnswers[],
  question: ExternalImageChoiceRespondentSurvey
): ImageChoiceAnswerType['values'] => {
  return responses
    .filter(response => !!response.answerId)
    .map(imgCBResponse => {
      if (question.answerNA && question.answerNA.id === imgCBResponse.answerId) {
        return {
          id: imgCBResponse.answerId,
          value: imgCBResponse.answerId,
          type: 'NOTA',
        };
      }

      return {
        id: imgCBResponse.answerId as string,
        value: imgCBResponse.answerId as string,
      };
    });
};

const mapNPSAnswerValues = (responses: ExistingAnswers[]): NpsAnswerType['values'] => {
  return responses
    .filter(r => !!r.answerNum && !!r.answerId)
    .map(r => ({
      id: r.answerId as string,
      value: r.answerNum as string,
    })) as NpsAnswerType['values'];
};

const mapStarRatingAnswerValues = (
  responses: ExistingAnswers[],
  question: Pick<ExternalStarRatingRespondentSurvey, 'id' | 'answerOther'>
): StarRatingAnswerType['values'] => {
  const [mappedResponse] = responses
    .filter(r => !!r.answerNum && !!r.answerId)
    .map(r => ({
      id: r.answerId as string,
      value: r.answerNum as string,
    })) as StarRatingAnswerType['values'];
  const mappedOtherResponse = findMappedOtherResponse(responses, question.answerOther as AnswerOther);

  if (mappedOtherResponse?.type === 'COMMENT') {
    return mappedResponse ? [mappedResponse, mappedOtherResponse] : [mappedOtherResponse];
  }

  return mappedResponse ? [mappedResponse] : [];
};

const mapAnswerValues = (responses: ExistingAnswers[], question: ExternalQuestion): RespondentAnswerTypes['values'] => {
  const { family, variant } = question;

  switch (family as SurveyQuestionFamily) {
    case 'OPEN_ENDED': {
      return mapOpenEndedAnswerValues(responses);
    }
    case 'MULTIPLE_CHOICE': {
      switch (variant as SurveyQuestionVariant) {
        case 'SINGLE_ANSWER_RADIO': {
          return mapMultipleChoiceAnswerValues(responses, question);
        }
        case 'SINGLE_ANSWER_IMAGE': {
          return mapSingleAnswerImageValue(responses);
        }
        case 'MANY_ANSWERS_CHECKBOX': {
          return mapCheckBoxAnswerValues(responses, question as ExternalCheckBoxRespondentSurvey);
        }
        case 'MANY_ANSWERS_IMAGE': {
          return mapMultipleAnswerImageValue(responses, question as ExternalImageChoiceRespondentSurvey);
        }
        case 'SINGLE_ANSWER_MENU': {
          return mapSingleAnswerDropdown(responses, question);
        }
        default: {
          return [];
        }
      }
    }
    case 'RATING_SCALE': {
      switch (variant as SurveyQuestionVariant) {
        case 'NPS': {
          return mapNPSAnswerValues(responses);
        }
        case 'SINGLE_ANSWER_SYMBOL': {
          return mapStarRatingAnswerValues(responses, question);
        }
        case 'SINGLE_ANSWER_RADIO': {
          return mapMatrixRadioAnswerValues(responses, question);
        }
        default: {
          return [];
        }
      }
    }
    case 'MATRIX':
      switch (variant as SurveyQuestionVariant) {
        case 'SINGLE_ANSWER_RADIO': {
          return mapMatrixRadioAnswerValues(responses, question);
        }
        case 'MANY_ANSWERS_CHECKBOX': {
          return mapManyMatrixCheckboxAnswerValues(responses, question);
        }
        case 'MENU': {
          return mapMatrixOfDropdownMenuAnswerValues(responses, question);
        }
        default: {
          return [];
        }
      }

    default: {
      if (process.env.NODE_ENV === 'development') {
        /**
         * This is a logging that would only happen during development. It is not expected to happen in production as it will be removed by the compiler.
         */
        console.debug(`Question type not mapped: ${family}, ${variant}`);
      }
      return [];
    }
  }
};

/**
 * Extract the visible answer options from a question
 *
 * @param question The question to extract visible answer options from
 * @returns Object containing arrays of visible answer IDs and other visibility flags
 */
function getVisibleAnswerOptions(question: ExternalQuestion): VisibleAnswerOptions {
  return {
    answers:
      'answers' in question && question.answers
        ? question.answers.filter(answer => answer.visible).map(answer => answer.id)
        : [],
    fieldSets:
      'fieldSets' in question && question.fieldSets
        ? question.fieldSets.filter(answer => answer.visible).map(answer => answer.id)
        : [],
    answersWeighted:
      'answersWeighted' in question && question.answersWeighted
        ? question.answersWeighted.filter(answer => answer.visible).map(answer => answer.id)
        : [],
    answersImage:
      'answersImage' in question && question.answersImage
        ? question.answersImage.filter(answer => answer.visible).map(answer => answer.id)
        : [],
    other:
      'answerOther' in question && question.answerOther && question.answerOther.visible
        ? question.answerOther.id
        : null,
    answerNA: 'answerNA' in question && question.answerNA && question.answerNA.visible ? question.answerNA.id : null,
    fieldSet: 'fieldSet' in question && question.fieldSet && question.fieldSet.visible ? question.fieldSet.id : null,
  };
}

/**
 * Converts server responses to application state answers.
 *
 * @param {Partial<RespApiAnswerInput>[]} responses - The list of responses from the server.
 * @param {ExternalQuestion[]} questions - The list of questions on the current page.
 * @returns {QuestionAnswers} - The mapped answers in the application state format.
 */
export const mapResponseToSurveyAnswers = (
  responses: ExistingAnswers[],
  questions: ExternalQuestion[]
): QuestionAnswers => {
  return questions.reduce<QuestionAnswers>((acc, question) => {
    let backendResponses = responses.filter(response => response.questionId === question.id);

    if (backendResponses.length === 0) {
      return acc;
    }

    const visibleAnswerOptions = getVisibleAnswerOptions(question);

    backendResponses = backendResponses.filter(response => {
      if (response.answerId && response.answerId !== '0') {
        const isVisibleById =
          visibleAnswerOptions.answers.includes(response.answerId) ||
          visibleAnswerOptions.fieldSets.includes(response.answerId) ||
          visibleAnswerOptions.answersImage.includes(response.answerId) ||
          visibleAnswerOptions.answersWeighted.includes(response.answerId) ||
          visibleAnswerOptions.other === response.answerId ||
          visibleAnswerOptions.fieldSet === response.answerId ||
          visibleAnswerOptions.answerNA === response.answerId;

        if (isVisibleById) {
          return true;
        }
      }

      if (response.answerNum && visibleAnswerOptions.answersWeighted.includes(response.answerNum)) {
        return true;
      }

      /**
       * Skips when answerId is 0, which is the default value for a question that does not have an answerId.
       */
      if (response.answerId === '0' && response.answerText) {
        /**
         * For an answer other, there's no answerId, so we have no way of knowing if it's visible or not. So we must
         * check the question's answerOther property to see if it's visible.
         */
        const isOtherHidden = 'answerOther' in question && question.answerOther && !question.answerOther.visible;
        return !isOtherHidden;
      }

      return false;
    });

    const values = mapAnswerValues(backendResponses, question);

    acc[question.id] = {
      questionId: question.id,
      values,
      isDirty: false,
      touched: false,
      family: question.family,
      variant: question.variant,
    };
    return acc;
  }, {});
};

export default mapResponseToSurveyAnswers;
